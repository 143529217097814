// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/ProjectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/TagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

